<template>
  <div class="page-layout details" v-if="pageData && pageData.attributes">
    
    <!--<HeaderIntroImage :headerMetadata="pageData"></HeaderIntroImage>-->
    <HeaderIntroImage underlined :headerMetadata="pageData"></HeaderIntroImage>
    
    <div class="container" v-if="pageData.attributes.field_page_main_content">
      <div class="page-body blue-title" v-html="this.pageData.attributes.field_page_main_content.value"></div>
    </div>

    <BlockWithImage v-if="pageData.field_ref_page_content" :imageurl="block1">
      <h2 class="">{{ this.pageData.field_ref_page_content[0].attributes.field_titolo.value }}</h2>
      <div class="list-1" v-html="this.pageData.field_ref_page_content[0].attributes.field_text_with_summary.value"></div>
      <a target="_blank" href="https://qt.eu/about-quantum-flagship/jobs-quantum-technologies/?q=&tags%5B%5D=internship" class="btn plain">
        <span class="icon-right external">View</span>
      </a>
    </BlockWithImage>

    <br>
    <br>

    <BlockWithImage mirror v-if="pageData.field_ref_page_content" :imageurl="block2">
      <h2 class="">{{ this.pageData.field_ref_page_content[1].attributes.field_titolo.value }}</h2>
      <div class="list-1" v-html="this.pageData.field_ref_page_content[1].attributes.field_text_with_summary.value"></div>
      <router-link @click.native="goto()" to="/programs-courses-and-trainings/higher-education" class="btn plain"><span>More</span></router-link>
    </BlockWithImage>

  </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import BlockWithImage from '../components/layout-blocks/BlockWithImage.vue'
//import MemberCard from '../components/layout-blocks/MemberCard.vue'
import { fetchSinglePage } from '../libs/drupalClient'
export default {
  name: 'about',
  components: {
    HeaderIntroImage,
    BlockWithImage
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "Internship Opportunities",
          tag: "",
          subtitle: ""
        }
      },
      currentPageID: "b9e2be6b-e77c-448d-a63d-fac61203e5db"
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    block1() {
      return require('../assets/block_img_2.png')
    },
    block2() {
      return require('../assets/block_img_3.jpg')
    }
  },
  methods: {
    goto() {
      this.$store.commit('SET_INTERNSHIP', true)
      //console.log(this.$store.state.temp_internship)
      //this.$router.push('/programs-courses-and-trainings/higher-education')
    }
  },
  mounted() {
    fetchSinglePage(this.currentPageID, {
      include: ['field_ref_page_content', 'field_ref_image', 'field_attachments']
    })
    .then(res => {
      this.pageData = res
      //console.log('pagedata', this.pageData)
    })
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
</style>