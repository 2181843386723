import { render, staticRenderFns } from "./BlockWithImage.vue?vue&type=template&id=057ded23&scoped=true&"
import script from "./BlockWithImage.vue?vue&type=script&lang=js&"
export * from "./BlockWithImage.vue?vue&type=script&lang=js&"
import style0 from "./BlockWithImage.vue?vue&type=style&index=0&id=057ded23&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "057ded23",
  null
  
)

export default component.exports