<template>
    <div :class="['double-block', mirror ? 'mirror' : '']" >
        <div class="back-color"></div>
        <div class="text">
            <slot></slot>
        </div>
        <div class="image">
            <picture>
                <img :src="imageurl" alt="block image">
            </picture>
        </div>
    </div>
</template>

<script>
export default {
    name: 'block-with-image',
    components: {},
    props: {
        mirror: Boolean,
        imageurl: String
    },
    data: () => {
        return {}
    },
    methods: {},
    computed: {},
    mounted() {},
    watch: {}
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.double-block {
    height: 100%;
    width: 100%;
    margin: 0;
    position: relative;
    margin-bottom: 5vh;
}

.text { 
    position: relative;
    z-index: 2;
    background-color: $lightGray;
    width: 100%;
    padding: 1em;
}
.back-color {
    position: absolute;
    z-index: 0;
    background-color: $lightBlue;
    width: 100%;
    height: calc(100% + 5vh);
}
.image {
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    // &:after {
    //     position: absolute;
    //     content: '';
    //     height: 100%;
    //     background: rgba(7, 17, 82, .2);
    //     width: 100%;
    //     top: 0;
    //     left: 0;
    // }
    &:before {
        position: absolute;
        content: '';
        height: 100%;
        background: url(../../assets/pattern_point_grey.png);
        opacity: .3;
        width: 100%;
        top: 0;
        left: 0;
    }
}

@media (min-width: 576px) {
    .double-block {
        margin-bottom: 20vh;
    }

    .text {
        width: 75%;
        height: 100%;
        padding: 1em;
        margin-left: 0;
        margin-right: auto;
    }

    .back-color {
        width: 70%;
        height: calc(100% + 20vh);
        right: 0;
        top: 0;
    }

    .image {
        position: absolute;
        z-index: 1;
        width: 60%;
        height: calc(100% + 10vh);
        right: 0;
        top: 0;
    }

    .mirror {
        .text {
            margin-left: auto;
            margin-right: 0;
        }
        .image {
            right: unset;
            left: 0;
        }
        .back-color {
            right: unset;
            left: 0
        }
    }
}

@media (min-width: 768px) {
    .text {
        width: 65%;
        padding: 2em;
    }    
}

@media (min-width: 1024px) {
    .text { 
        width: 55%;
        padding: 2em 3em 2em 4em; 
    }
    .mirror .text { padding: 2em 4em 2em 3em; }
}

@media (min-width: 1200px) {
    .text { padding: 2em 4em 2em 15%; }
    .mirror .text { padding: 2em 15% 2em 4em; }
      .double-block {
        margin-bottom: 20vh;
    }
}
   
@media (min-width: 1600px) {
    .text { padding: 2em 10% 2em 20%; }
    .mirror .text { padding: 2em 20% 2em 10%; }
}

</style>